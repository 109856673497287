import moment from 'moment-timezone';

const getTimeByTimeZone = (date = new Date(), timezone = 'America/Denver') => {
  return moment.tz(date, timezone).format('h:mm A');
};

/**
 * Checks if a given day number is past the current day of the month.
 * @param targetDay The day number to compare against the current day of the month.
 * @returns True if the target day is in the past, false otherwise.
 */
const isDayOfMonthPassed = (targetDay: number): boolean => {
  // Get the current day of the month using Moment.js
  const currentDayOfMonth = moment().date();

  // Compare the current day of the month with the target day
  return currentDayOfMonth > targetDay;
};

const getUTCDateInYearMonthDayFormatAsString = (date: string, format = 'YYYY-MM-DD'): string =>
  moment.utc(date).format(format);

const getTomorrowMomentDate = (): moment.Moment => moment().add(1, 'day');

/**
 * Returns the abbreviated timezone name (e.g., 'EST', 'PDT') for a given timezone identifier
 *
 * @param timezone - IANA timezone identifier (e.g., 'America/New_York', 'America/Los_Angeles')
 * @returns The abbreviated timezone name (e.g., 'EST', 'PDT')
 *
 * @example
 * ```typescript
 * getTimezoneAbbreviation('America/New_York') // Returns 'EST' or 'EDT' depending on DST
 * getTimezoneAbbreviation('America/Los_Angeles') // Returns 'PST' or 'PDT' depending on DST
 * ```
 */
const getTimezoneAbbreviation = (timezone: string): string => moment().tz(timezone).format('z');

/**
 * Returns a Date object representing one month ago from the current date
 * @returns {Date} A Date object set to one month before the current date
 */
export const getOneMonthAgoDate = (): Date => {
  const date = new Date();
  date.setMonth(date.getMonth() - 1);
  return date;
};

export default {
  getTimeByTimeZone,
  isDayOfMonthPassed,
  getUTCDateInYearMonthDayFormatAsString,
  getTomorrowMomentDate,
  getTimezoneAbbreviation,
  getOneMonthAgoDate,
};
